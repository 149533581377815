import PigDiceGame from '../../images/Dev/PigDiceGame.jpg';
import SimonSays from '../../images/Dev/SimonSays.PNG';
import SmoothCalculator from '../../images/Dev/SmoothCalculator.jpg';
import TicTacToe from '../../images/Dev/TicTacToe.jpg';

export const shopAppLoginData = { email: "johndoe@gmail.com", password: "testtest123", title: "Admin profile" }


export const betAppLoginData = { email: "dennisiasip@hotmail.com", password: "testtest123", title: "Pre-created profile" };

export const codepenTitles = [
    {
        title: "Tic Tac Toe",
        text: "Test yourself against HAL, the ultimate chess champion, or play a game against a friend.",
        link: "https://codepen.io/PatrickBateman92/full/REjBJd",
        imgSrc: TicTacToe
    },
    {
        title: "Calculator",
        text: "It's a simple calculator. One of the final exams for freeCodeCamp front-end certificate.",
        link: "https://codepen.io/PatrickBateman92/full/qQyydX",
        imgSrc: SmoothCalculator
    },
    {
        title: "Pig Dice Game",
        text: '"Do you feel lucky punk", asked Clint Eastwood in Dirty Harry. This is no cop movie, but it is a game of risk. So, do you feel lucky?',
        link: "https://codepen.io/PatrickBateman92/full/zyxPRO",
        imgSrc: PigDiceGame
    }, {
        title: "Simon Says",
        text: "How is your memory? Do you better remember visuals or audio? Test yourself, and try to beat the game's 20 levels.",
        link: "https://codepen.io/PatrickBateman92/full/REagXN",
        imgSrc: SimonSays
    }
];